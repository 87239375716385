import { Injectable } from '@angular/core';
import { Router, Event as NavigationEvent, NavigationEnd } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class BodyBackgroundService {
  html: HTMLElement = document.getElementsByTagName('html')[0];
  body: HTMLElement = document.getElementsByTagName('body')[0];

  constructor(private readonly router: Router) {}

  subscribeToRouteEvents(): void {
    this.router.events.subscribe((event: NavigationEvent) => {
      if (event instanceof NavigationEnd) {
        this.onRouteChange(event.url);
      }
    });
  }

  private onRouteChange(url: string): void {
    if (['/', '/login', '/home', '/registration/registration-splash'].includes(url)) {
      this.setBackground();
    } else {
      this.removeBackground();
    }
  }

  setBackground(): void {
    this.html.style.setProperty('--ion-background-color', 'url("assets/splash_bck.jpg") no-repeat center/cover fixed');
    this.html.style.setProperty('--ion-toolbar-background', 'transparent');
    this.body.classList.add('background');
  }

  removeBackground(): void {
    this.html.style.setProperty('--ion-background-color', '#FFFEF9');
    this.html.style.setProperty('--ion-toolbar-background', '#FFFEF9');
    this.body.classList.remove('background');
  }
}
